import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				The Ultimate Pitch
			</title>
			<meta name={"description"} content={"Su principal destino de alquiler de campos de fútbol"} />
			<meta property={"og:title"} content={"The Ultimate Pitch"} />
			<meta property={"og:description"} content={"Su principal destino de alquiler de campos de fútbol"} />
			<meta property={"og:image"} content={"https://aventurinesky.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://aventurinesky.com/img/7419954.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aventurinesky.com/img/7419954.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aventurinesky.com/img/7419954.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aventurinesky.com/img/7419954.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aventurinesky.com/img/7419954.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aventurinesky.com/img/7419954.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1" id="about">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://aventurinesky.com/img/7.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					max-height="450px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
					align-self="flex-start"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 900 42px/1.2 --fontFamily-mono"
						border-style="solid"
						border-width="0 0 4px 0"
						border-color="--color-primary"
						padding="0px 0px 20px 0px"
						width="100%"
					>
						Nuestra oferta de fútbol de primera
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="0px 0px 20px 0px"
						display="flex"
						flex-direction="column"
						lg-width="100%"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						lg-padding="0px 16px 16px 16px"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--base"
							lg-text-align="center"
							lg-width="100%"
							md-text-align="left"
						>
							En The Ultimate Pitch, nos enorgullecemos de ofrecer una amplia gama de servicios que satisfacen las necesidades de todos los aficionados al fútbol. Si usted está planeando un partido casual con amigos, la organización de una liga, o la celebración de un evento corporativo, nuestros servicios están diseñados para asegurarse de que tiene todo lo necesario para una experiencia memorable. Nuestro compromiso con la excelencia se extiende más allá de nuestro campo, ofrecemos una variedad de servicios adicionales y apoyo para mejorar su tiempo con nosotros.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1" id="about">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
					align-self="flex-start"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 900 42px/1.2 --fontFamily-mono"
						border-style="solid"
						border-width="0 0 4px 0"
						border-color="--color-primary"
						padding="0px 0px 20px 0px"
						width="100%"
					>
						Instalaciones de nivel profesional
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="0px 0px 20px 0px"
						display="flex"
						flex-direction="column"
						lg-width="100%"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						lg-padding="0px 16px 16px 16px"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--base"
							lg-text-align="center"
							lg-width="100%"
							md-text-align="left"
						>
							Nuestro campo no es sólo un lugar para jugar: es un lugar para destacar. Ofrecemos instalaciones de primera categoría que rivalizan con los estándares profesionales. Desde la calidad de nuestro césped hasta la comodidad de nuestras instalaciones, cada detalle está meticulosamente planificado para ofrecerte la mejor experiencia futbolística posible.
							<br/><br/>
Césped de última generación: Nuestro césped sintético está diseñado para ofrecer el equilibrio perfecto entre rendimiento y seguridad. Proporciona una excelente tracción, reduce el riesgo de lesiones e imita el tacto de la hierba natural.
    Durabilidad: Nuestro césped e

						</Text>
					</Box>
				</Box>
				<Image
					src="https://aventurinesky.com/img/8.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					max-height="450px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 900 42px/1.2 --fontFamily-mono"
				padding="0px 0px 20px 0px"
				width="100%"
				text-align="center"
			>
				Opciones de alquiler versátiles
			</Text>
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Alquiler por horas
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ideal para partidos ocasionales y sesiones de práctica. Reserve el campo por horas y disfrute de un entorno de juego de primera categoría sin ningún compromiso a largo plazo.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Precios asequibles
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Los precios competitivos le garantizan una excelente relación calidad-precio.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Fácil reserva
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Proceso de reserva sencillo y directo para que pueda salir al campo rápidamente.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Alquileres a largo plazo
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Perfecto para ligas, clubes y organizaciones que requieren acceso regular a un campo de fútbol de alta calidad.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1" id="about">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://aventurinesky.com/img/9.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					max-height="450px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
					align-self="flex-start"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 900 42px/1.2 --fontFamily-mono"
						border-style="solid"
						border-width="0 0 4px 0"
						border-color="--color-primary"
						padding="0px 0px 20px 0px"
						width="100%"
					>
						¿Listo para elevar tu juego?
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="0px 0px 20px 0px"
						display="flex"
						flex-direction="column"
						lg-width="100%"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						lg-padding="0px 16px 16px 16px"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--base"
							lg-text-align="center"
							lg-width="100%"
							md-text-align="left"
						>
							Únase a nosotros en The Ultimate Pitch y viva el fútbol como nunca antes. Póngase en contacto con nosotros hoy mismo para obtener más información sobre nuestros servicios y reservar su campo.
							<br/><br/>
							Gracias por considerar The Ultimate Pitch. Esperamos poder ofrecerle una experiencia futbolística excepcional.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});